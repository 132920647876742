.whoIAmInChrist, .salvation{
    background-color: rgb(236, 51, 16,0.5) !important;
    /* color: #EC3310; */
    color: white;
    border: #EC3310 1px solid;
   
}
.theImportanceOfTheLocalChurch, .rededication, .spiritualWarfare{
    background-color: rgb(236, 113, 133,.5)  !important;
    /* color: rgb(236, 113, 133); */
    color:white;
    border: rgb(236, 113, 133) 1px solid;
}
.romans8LifeInTheSpiritIsVictory, .physicalTest5{
    background-color: #2DB3C0 !important;
}

.faith, .physicalResource1Test, .ongoingprayer {
    background-color: #FF7234 !important;
}
.godsPresenceToComfort, .forgiveness , .literature{
    background-color: rgb(236, 113, 133, 0.8)
    !important;
    border: #2DB3C0 1px solid;
    /* color: #2DB3C0; */
    color: black;
    
}
.peace{
    background-color:rgb(25, 162, 25) !important;
}
.prayerBibleReading, .prayercall {
    background-color: #00323B !important;
    color: white;
}
.hope, .addiction{
    background-color: #FCD5C9 !important;
    color: white;
}
.finances, .walkoffaith{
    background-color: purple !important;
    color: #E0E0E0;
}
.healingBodySoulSpirit, .answertoprayer{
    background-color: #FFEAE4 !important;
    color:black !important;
}
.healingPersonalizedPrayer{
    background-color: rgb(169, 229, 49) !important;
}
.familyconcerns, .emotionalNeeds, .suicidecall  {
    background-color: #FF7234 !important;
    color: white;
}
.healing{
    background-color: powderblue !important;
}
.guidance, .churchreferrals{
    background-color: #EC3310 !important;
}
.status{
    display: flex;
    padding: 1px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 8px;

&.new {
    border: 1px solid #7FC008;
    background: rgba(127, 192, 8, 0.20);
}
&.completed {
    border: 1px solid #7FC008;
    background: rgba(127, 192, 8, 0.20);
}
&.ongoing {
    border: 1px solid #FF7234;
    background: rgba(219, 140, 40, 0.20);
}
&.completed{
    
border: 1px solid  #DB303F;
background: rgba(219, 48, 63, 0.20);
}
}
/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "rsuite/dist/rsuite.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  font-family: "DM Serif Display"
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table{
  font-family: "Futura PT";
}
.react-calendar{
  align-content: center;
    border-radius: 20px;
    border: 2px solid #77B3B8 !important;
    background: #FFF;
    box-shadow: 0px 4px 32px 0px rgba(170, 170, 170, 0.03);
    padding: 20px;
    width: 90% !important;
    height: 100%;
}
.react-calendar__tile--active{
  border-radius: 50%;
  background-color: #FF7234 !important;
  
}
.react-calendar__tile{
  height: 4rem;
  width: 4rem;
  &:hover{
    border-radius: 50%;
  }
}
.react-calendar__tile--now {
  border-radius: 50%;
  background-color: rgb(45, 179, 192,.5) !important;
}